

import React, { useState, useEffect } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Tabs from '@mui/material/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { DoModal, ModalContent } from "../../utils/Modal";
import { Height, Margin, Padding } from '@mui/icons-material';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';

import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';

import '../users/UsersAndRoles.css';
import AvatarSelect from '../../components/avatarSelect';
import GroupsMan from './GroupsMan';


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const permColumns = [
    { field: 'permission_id', headerName: 'ID', width: 90 },
    {
        field: 'name', headerName: 'Name',
        width: 350,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        width: 350,
    },
    {
        field: 'permission_code',
        headerName: 'Code',
        width: 250,
    },
];

const columnGroupingModel = [
    {
        groupId: 'Internal',
        description: '',
        children: [{ field: 'id' }],
    },
    {
        groupId: 'Basic info',
        children: [
            {
                groupId: 'Full name',
                children: [{ field: 'last_name' }, { field: 'first_name' }],
            },
            { field: 'age' },
        ],
    },
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function GroupAndRoles() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [groupData, setGroupData] = useState({});
    const [sysrolesData, setSysRolesData] = useState([]);


    // Roles
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    //Permission
    const [gridPermData, setGridPermData] = useState([]);

    const auth = useAuth();
    const onSubmit = data => {
        alert('Save group data');
    }

    const [isOpen, setIsopen] = useState(false);
    const showModal = () => setIsopen((prev) => !prev);

    const [tabValue, setTabValue] = useState(0);

    const childRef = useRef();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const setAvatar = (avatar) => {

        fetch(avatar['img'])
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((res) => {
                    reader.onloadend = () => {
                        res(reader.result);
                    }
                })
            }).then(data => {
                console.log("IMG=", data);


                setGroupData({ ...groupData });
            })


    }

    const handleRolesToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleRolesAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleRolesCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleRolesCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleRolesAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const handleSaveGroupRoles = () => {
        if (!groupData['group_id']) {
            return
        }

        let roles = left.map(a => a.role_id);

        console.log("Roles:=", roles);
        fetch("/api/grouproles/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                group_id: groupData['group_id'],
                roles: roles
            }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                alert("Save group roles ok");
            })
            .catch((error) => console.log(error));
        //fetchData();

    }


    const customRolesList = (items) => (
        <Paper sx={{ width: 400, height: 430, overflow: 'auto' }}>

            <List dense component="div" role="list">
                {items.map((e) => {
                    const labelId = `transfer-list-item-${e.role_id}-label`;

                    return (
                        <ListItemButton
                            key={e.role_id}
                            role="listitem"
                            onClick={handleRolesToggle(e)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(e) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${e.role_name}`} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    useEffect(() => {
        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/roles", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setSysRolesData(rowData);
                setRight(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    const loadRoles = (group_id) => {
        fetch("/api/grouproles/" + group_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setLeft(rowData);

                let right = [];
                sysrolesData.forEach((e) => {
                    let found = rowData.find((element) => element.role_id === e.role_id);
                    if (!found) {
                        right.push(e);
                    }
                });

                setRight(right);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const loadGroupPerm = (group_id) => {
        fetch("/api/groupperm/" + group_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setGridPermData(rowData);

            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    const selectGroup = (data, next) => {
        setGroupData({ ...data });
        setValue("group_id", data['group_id']);
        setValue("group_name", data['group_name']);
        setValue("notes", data['notes']);
        setValue("group_type", data['group_type']);
        setValue("state", data['state']);
        childRef.current?.callReloadData(data['group_id']);

        setGroupData(data);
        loadRoles(data['group_id']);
        loadGroupPerm(data['group_id']);
    }

    return (
        <div style={{ margin: "30px" }}>
            {<GroupsMan selectGroup={selectGroup} />}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="User Tabs">
                        <Tab label="Group Info" {...a11yProps(0)} style={{ textTransform: 'none', }} />
                        <Tab label="Roles" {...a11yProps(1)} style={{ textTransform: 'none', }} />
                        <Tab label="Permissions" {...a11yProps(2)} style={{ textTransform: 'none', }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <h3> Group info</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Container className="p-3 ">
                            <Row style={{ width: "100%" }}>
                                <Col md="6">
                                    <div className="mb-3">
                                        <label>Group ID:</label>&nbsp;<br />
                                        <input type="text" placeholder="group_id" {...register("group_id", { required: true })} disabled={true} />
                                    </div>

                                    <div className="mb-3">
                                        <label>Group Name:</label>&nbsp;<br />
                                        <input type="text" placeholder="group_name" {...register("group_name", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Notes:</label>&nbsp;<br />
                                        <input type="text" placeholder="notes" {...register("notes", {})} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Group Type:</label>&nbsp;<br />
                                        <input type="text" placeholder="group_type" {...register("group_type", { required: true })} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Status:</label>&nbsp;<br />
                                        <select {...register("state")}>
                                            <option value="1">Active</option>
                                            <option value="2">Lock</option>
                                            <option value="9">Deleted</option>
                                        </select>
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <h3>Roles</h3>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">

                        <Grid item>Roles for this group{customRolesList(left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleRolesAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleRolesCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleRolesCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleRolesAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>Roles available{customRolesList(right)}</Grid>

                    </Grid>
                    <button type="button" className="btn btn-primary" onClick={handleSaveGroupRoles}>Save</button>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <h3>Permissions</h3>
                    <DataGrid
                        rows={gridPermData}
                        columns={permColumns}
                        checkboxSelection={false}
                        disableMultipleRowSelection={true}
                        //columnGroupingModel={columnGroupingModel}
                        density='compact'
                        // onRowClick={handleRowClick}
                        pageSizeOptions={[5, 10, 25]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                    />
                </CustomTabPanel>

            </Box>




        </div >
    );
}




